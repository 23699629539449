import React, { useState, useEffect } from "react";
import "./homePage.css";
import firebaseDb from "../../firebase.js";

const Homepage = () => {
  const initialData = {
    t1: "--",
    t2: "--",
    t3: "--",
    t4: "--",
    t5: "--",
    t6: "--",
    t7: "--",
    t8: "--",
    t9: "--",
    t10: "--",
  };
  const [data, setData] = useState(initialData);

  const getTodayDate = () => {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var date = today.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    var finalDate = year + "-" + month + "-" + date;
    return finalDate;
  };

  const getData = (date) => {
    firebaseDb
      .collection("/data")
      .where("date", "==", date)
      .onSnapshot((a) => {
        if (a.size > 0) {
          a.forEach((i) => {
            setData(i.data());
            setSearch(date);
          });
        } else {
          setData(initialData);
          setSearch(date);
        }
      });
  };

  const todayDate = getTodayDate();

  useEffect(() => {
    getData(todayDate);
  }, []);

  const [search, setSearch] = useState("");
  const handle = (e) => {
    setSearch(e.target.value);
  };
  const searchDB = () => {
    getData(search);
  };

  return (
    <div className="homeTable">
      <div className="container">
        <div className="row search-box">
          <div className="col-md-4 col-sm-12 col-xs-4 dpicker">
            <input
              type="date"
              value={search}
              onChange={handle}
              class="form-control datepicker"
              id="date"
              name="date"
              placeholder="search"
            />
          </div>
          <div className="col-md-8 col-sm-12 col-xs-8 sbtn">
            <button className="btn btn-success search" onClick={searchDB}>
              search
            </button>
            <button
              className="btn btn-danger reset"
              onClick={() => getData(todayDate)}
            >
              reset
            </button>
          </div>
        </div>
      </div>
      <table class="table table-bordered">
        <tbody>
          <tr className="customHead">
            <th scope="row">Time</th>
            <th scope="row">Result</th>
          </tr>
          <tr>
            <td>10 : 30 AM</td>
            <td>{data.t1}</td>
          </tr>
          <tr>
            <td>11 : 30 AM</td>
            <td>{data.t2}</td>
          </tr>
          <tr>
            <td>12 : 30 PM</td>
            <td>{data.t3}</td>
          </tr>
          <tr>
            <td>01 : 30 PM</td>
            <td>{data.t4}</td>
          </tr>
          <tr>
            <td>03 : 30 PM</td>
            <td>{data.t5}</td>
          </tr>
          <tr>
            <td>04 : 30 PM</td>
            <td>{data.t6}</td>
          </tr>
          <tr>
            <td>05 : 30 PM</td>
            <td>{data.t7}</td>
          </tr>
          <tr>
            <td>06 : 30 PM</td>
            <td>{data.t8}</td>
          </tr>
          <tr>
            <td>07 : 30 PM</td>
            <td>{data.t9}</td>
          </tr>
          <tr>
            <td>08 : 30 PM</td>
            <td>{data.t10}</td>
          </tr>
        </tbody>
      </table>
      {/* <h2 className="heading"><center>Ten plus one Jackpot</center></h2> */}
    </div>
  );
};

export default Homepage;
