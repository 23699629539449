import React from 'react';
import { Redirect } from 'react-router-dom'
import '../homePage/homePage.css'

const navbar = (props) => {

    const redirectToHome = () => {
        console.log("Hello")
        return (
            <Redirect path="/" />
        )
    }

    return (
        <nav class="navbar">
            <p className="brand" onClick={redirectToHome}>Ten plus one</p>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </nav>
    )
}

export default navbar